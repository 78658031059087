import { useState } from "react";
import { useTheme } from "@emotion/react";
import {
  Box,
  Button,
  ButtonGroup,
  Card,
  CardContent,
  CardMedia,
  Link,
  Stack,
  Typography,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { useSelector } from "react-redux";
import Iconify from "src/components/Iconify";
import { SupportsWebp } from "src/slices/appSlice";
import { resolvePrimaryImageSrc } from "src/utils/imageLinks";
import { LoadingButton } from "@mui/lab";
import AddToCartButton from "src/features/product/AddToCartButton";

export default function ProductCard({
  productWithLine,
  detailsRoute = "#",
  buttonText = "Add to Cart",
  updateCartLineQuantity,
  onSelect = null,
  buttonIcon,
  isUpdating,
  isDisabled = false,
  inNewTab = false,
  inModal = false,
  hidePrice = false,
}) {
  const supportsWebp = useSelector(SupportsWebp);
  const theme = useTheme();
  const line = productWithLine.cartLine;

  const [imgSrc, setImgSrc] = useState(
    resolvePrimaryImageSrc(
      "product",
      productWithLine.id,
      productWithLine.primaryImageFileNamePrefix,
      "md",
      supportsWebp ? "webp" : "jpg"
    )
  );

  return (
    <Card
      key={productWithLine.id}
      variant="outlined"
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        maxHeight: "175px",
        maxWidth: "600px",
        width: "100%",
        overflow: "hidden",
      }}
    >
      <CardMedia
        component="img"
        sx={{
          width: {
            xs: "128px",
            sm: "175px",
          },
          height: "175px",
          objectFit: "cover",
          flex: "0 1 auto",
        }}
        image={imgSrc}
        onError={() => setImgSrc("/static/logo512.png")}
        alt={productWithLine.name}
      />
      <CardContent
        sx={{
          width: 1,
          maxWidth: {
            xs: inModal ? "calc(100vw - 235px)" : "calc(100vw - 165px)", // TODO: Temporary force fix for modal
            sm: "calc(100% - 175px)",
          },
          p: "16px !important",
        }}
        align="left"
      >
        <Stack
          display="flex"
          direction="column"
          alignItems="stretch"
          justifyContent="space-between"
          sx={{ height: "100%" }}
        >
          <Box sx={{ minWidth: 0 }}>
            <Typography
              variant="h3"
              noWrap
              sx={{
                size: "14px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                width: "100%",
              }}
            >
              {productWithLine.name}
            </Typography>
            <Typography
              variant="body2"
              noWrap
              sx={{
                size: "12px",
                fontFamily: "karla",
                overflow: "hidden",
                textOverflow: "ellipsis",
                width: "100%",
              }}
            >
              {productWithLine.shortDescription}
            </Typography>
          </Box>
          <Box>
            {hidePrice ? (
              <Box sx={{ height: "1.5rem" }} />
            ) : (
              <Typography
                variant="h4"
                sx={{
                  fontFamily: "karla",
                }}
              >
                ${productWithLine.priceUnitAmount.toFixed(2)}
              </Typography>
            )}
            <Stack
              direction={{ xs: "column", sm: "row" }}
              justifyContent="space-between"
              spacing={1}
            >
              <Link
                sx={{
                  marginTop: "5px",
                  textDecoration: "none",
                  "&:hover": {
                    textDecoration: "underline",
                  },
                }}
                component={RouterLink}
                to={detailsRoute}
                target={inNewTab ? "_blank" : undefined}
                rel={inNewTab ? "noopener noreferrer" : undefined}
              >
                see details
              </Link>
              {line ? (
                <ButtonGroup size="small">
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={() =>
                      updateCartLineQuantity(line.id, line.quantity - 1)
                    }
                    disabled={isUpdating}
                  >
                    <Iconify
                      icon={line.quantity === 1 ? "mdi:trash" : "mdi:minus"}
                    />
                  </Button>
                  <LoadingButton
                    variant="outlined"
                    size="small"
                    disabled
                    loading={isUpdating}
                    sx={{
                      "&.Mui-disabled": {
                        borderColor: theme.palette.primary.main,
                        color: theme.palette.text.primary,
                      },
                    }}
                  >
                    {line.quantity}
                  </LoadingButton>
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={() =>
                      updateCartLineQuantity(line.id, line.quantity + 1)
                    }
                    disabled={isUpdating}
                  >
                    <Iconify icon="mdi:plus" />
                  </Button>
                </ButtonGroup>
              ) : onSelect ? (
                <LoadingButton
                  startIcon={
                    buttonIcon && (
                      <Iconify icon={buttonIcon} width={20} height={20} />
                    )
                  }
                  variant="contained"
                  size={theme.breakpoints.down("xs") ? "small" : "medium"}
                  onClick={onSelect}
                  disabled={isDisabled}
                  loading={isUpdating}
                >
                  {buttonText}
                </LoadingButton>
              ) : (
                <AddToCartButton
                  startIcon={
                    buttonIcon && (
                      <Iconify icon={buttonIcon} width={20} height={20} />
                    )
                  }
                  variant="contained"
                  size={theme.breakpoints.down("xs") ? "small" : "medium"}
                  loading={isUpdating}
                  disabled={isDisabled}
                  product={productWithLine}
                >
                  {buttonText}
                </AddToCartButton>
              )}
            </Stack>
          </Box>
        </Stack>
      </CardContent>
    </Card>
  );
}
