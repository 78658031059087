import { useQuery, useMutation, useQueryClient } from "react-query";
import api from "../services/api";
import { ObjectToQueryString } from "src/utils/uri";

// API methods - not exposed outside this class
const getOrders = async (params) => {
  const { data } = await api.get(`/order?${ObjectToQueryString(params)}`);
  return data;
};

// API methods - not exposed outside this class
const getOrder = async (orderId, partnerId) => {
  const { data } = await api.get(
    `/order/${orderId}?${ObjectToQueryString({ partnerId })}`
  );
  return data;
};

const getCart = async () => {
  const { data } = await api.get(`/order/cart`);
  return data;
};

const fulfillOrder = async ({ orderId, trackingUrl, orderLineIds }) => {
  const { data } = await api.post(`/order/${orderId}/fulfill`, {
    trackingUrl,
    orderLineIds,
  });
  return data;
};

const addtoCart = async ({
  obituaryId,
  productId = null,
  memorialId = null,
}) => {
  const { data } = await api.post(`/order/addToCart`, {
    obituaryId,
    productId,
    memorialId,
  });
  return data;
};

const upsertGiftToCart = async ({
  inviteId = null,
  productId,
  email,
  name,
  senderName,
  customMessage,
}) => {
  const endpoint = inviteId
    ? `/order/upsertGiftToCart/${inviteId}`
    : "/order/upsertGiftToCart";

  const { data } = await api.post(endpoint, {
    productId,
    email,
    name,
    senderName,
    customMessage,
  });

  return data;
};

const updateCartLine = async ({ lineId, quantity }) => {
  const { data } = await api.post(`/order/cartLine/${lineId}`, {
    quantity,
  });
  return data;
};

const updateCartAddress = async ({ addressId }) => {
  const { data } = await api.post(`/order/updateCartAddress`, {
    addressId,
  });
  return data;
};

const upsertCheckoutSession = async ({ originUrl }) => {
  const { data } = await api.post(`/order/cart/checkout`, { originUrl });
  return data;
};

// Custom hooks
export const usePollOrder = (orderId) => {
  return useQuery(
    ["pollOrder", { orderId: parseInt(orderId) }],
    () => getOrder(orderId),
    {
      refetchInterval: 500,
      refetchIntervalInBackground: true,
    }
  );
};

export const useOrder = (orderId, partnerId = null) => {
  return useQuery(
    ["order", { orderId: parseInt(orderId), partnerId }],
    () => getOrder(orderId, partnerId),
    { retry: false }
  );
};

export const useOrderList = ({
  page,
  perPage,
  userId,
  partnerId,
  orderStatuses,
  partnerActionRequired,
}) => {
  return useQuery(
    [
      "orderlist",
      {
        page: parseInt(page || 0),
        perPage,
        userId: parseInt(userId),
        partnerId: parseInt(partnerId),
        orderStatuses,
        partnerActionRequired,
      },
    ],
    () =>
      getOrders({
        page,
        perPage,
        userId,
        partnerId,
        orderStatuses,
        partnerActionRequired,
      })
  );
};

export const useFulfillOrder = () => {
  const queryClient = useQueryClient();

  return useMutation(fulfillOrder, {
    onSuccess: (data) => {
      queryClient.setQueryData(["order", { orderId: parseInt(data.id) }], data);
    },
  });
};

export const useCart = () => {
  return useQuery("Cart", () => {
    return getCart();
  });
};

export const useAddToCart = () => {
  const queryClient = useQueryClient();

  return useMutation(addtoCart, {
    onSuccess: (data) => {
      queryClient.setQueryData("Cart", data);
    },
  });
};

export const useUpsertGiftToCart = () => {
  const queryClient = useQueryClient();
  return useMutation(upsertGiftToCart, {
    onSuccess: (data) => {
      queryClient.setQueryData("Cart", data);
    },
  });
};

export const useUpdateCartLine = () => {
  const queryClient = useQueryClient();

  return useMutation(updateCartLine, {
    onSuccess: (data) => {
      queryClient.setQueryData("Cart", data);
    },
  });
};

export const useUpdateCartAddress = () => {
  const queryClient = useQueryClient();

  return useMutation(updateCartAddress, {
    onSuccess: (data) => {
      queryClient.setQueryData("Cart", data);
    },
  });
};

export const useUpsertCartCheckoutSession = () => {
  return useMutation(upsertCheckoutSession);
};
