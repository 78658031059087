export function UriSafe(strings, ...toEncode) {
  return strings.reduce((acc, val) => {
    return acc + val + encodeURIComponent(toEncode.splice(0, 1) || "");
  }, "");
}

export function ObjectToQueryString(obj) {
  let str = [];

  for (let p in obj) {
    if (obj.hasOwnProperty(p)) {
      let value = obj[p];

      if (value !== undefined && value !== null && value !== "") {
        if (Array.isArray(value)) {
          value.forEach((val) => {
            str.push(encodeURIComponent(p) + "=" + encodeURIComponent(val));
          });
        } else {
          str.push(encodeURIComponent(p) + "=" + encodeURIComponent(value));
        }
      }
    }
  }
  return str.join("&");
}
