import { useState } from "react";
import ProductCard from "src/pages/shop/ProductCard";
import AddressChooser from "src/pages/account/AddressChooser";
import AddressForm from "src/pages/account/AddressForm";
import {
  Button,
  Box,
  Stack,
  Container,
  Card,
  CardContent,
  Typography,
} from "@mui/material";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { UserId } from "src/slices/authSlice";
import Iconify from "src/components/Iconify";
import { useRedeemGiftInvitation } from "src/hooks/giftInvitation";
import GiftSummaryCard from "./GiftSummaryCard";
import { primaryObituaryImageLink } from "src/utils/imageLinks";
import ImgWithFallback from "src/components/ImgWithFallback";
import { formatDateYearUTC, formatUTCDateToText } from "../../utils/formatTime";
import palette from "src/theme/palette";
import { ProductCategory } from "src/utils/enums";

export const RedeemStep = ({ tributeId, tribute, onNext, giftMode }) => {
  const [addressChooserOpen, setAddressChooserOpen] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState(
    giftMode.defaultAddress
  );
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [asSummary, setAsSummary] = useState(false);

  const products = giftMode.giftBundleItems
    .filter((x) => x.childProductCategory === ProductCategory.PHYSICAL_GOOD)
    .map((y) => y.childProduct);
  const subscription = giftMode.giftBundleItems.find(
    (x) => x.childProductCategory === ProductCategory.SUBSCRIPTION
  ).childProduct;

  const userId = useSelector(UserId);
  const location = useLocation();
  const navigate = useNavigate();
  const redeemGiftInvitation = useRedeemGiftInvitation();

  const handleConfirm = async () => {
    const searchParams = new URLSearchParams(location.search);
    const verifyToken = searchParams.get("verifyToken");

    await redeemGiftInvitation.mutateAsync({
      redeemedForPhysicalProductId: selectedProduct.id,
      redeemedForObituaryId: tributeId,
      addressId: selectedAddress.id,
      encodedToken: verifyToken,
    });

    navigate(`/obituaries/${tributeId}`);
    onNext();
  };

  if (!asSummary) {
    return (
      <Container disableGutters={true}>
        <AddressChooser
          userId={userId}
          dialogTitleText="Select shipping address"
          open={addressChooserOpen}
          onClose={() => setAddressChooserOpen(false)}
          onSelected={async (address) => {
            setSelectedAddress(address);
            setAddressChooserOpen(false);
          }}
        />
        <Stack spacing={2} mt={1}>
          <Card
            sx={{
              backgroundColor: "unset",
              maxWidth: "600px",
              width: "100%",
            }}
          >
            <CardContent sx={{ m: 0, p: 0, "&:last-child": { pb: 0 } }}>
              {selectedAddress ? (
                <Button
                  display="block"
                  color="primary"
                  variant="text"
                  size="large"
                  startIcon={<Iconify icon="mdi:location" />}
                  sx={{ textTransform: "unset" }}
                  onClick={() => setAddressChooserOpen(true)}
                >
                  {`Deliver to ${selectedAddress.name} - ${selectedAddress.city} ${selectedAddress.zip}`}
                </Button>
              ) : (
                <AddressForm
                  formTitle="Enter Shipping Address:"
                  onSave={async (address) => {
                    setSelectedAddress(address);
                  }}
                  buttonText="Save Address and Continue"
                />
              )}
            </CardContent>
          </Card>

          {products &&
            products.map((product) => (
              <ProductCard
                key={product.id}
                detailsRoute={`/shop/${product.id}`}
                inNewTab={true}
                inModal={true}
                isDisabled={!selectedAddress}
                productWithLine={product}
                onSelect={() => {
                  setSelectedProduct(product);
                  setAsSummary(true);
                }}
                buttonText="Select"
                buttonIcon={"mdi:gift"}
                hidePrice={true}
              />
            ))}
        </Stack>
      </Container>
    );
  } else {
    return (
      <Container disableGutters={true}>
        <Stack mt={1}>
          <Typography variant="h5">Your Gift Summary:</Typography>

          <Card
            sx={{
              backgroundColor: "unset",
              maxWidth: "600px",
              width: "100%",
              my: 0,
              py: 0,
            }}
          >
            <CardContent sx={{ m: 0, p: 0, "&:last-child": { py: 1.5 } }}>
              {selectedAddress ? (
                <Button
                  display="block"
                  color="primary"
                  variant="text"
                  size="large"
                  startIcon={<Iconify icon="mdi:location" />}
                  sx={{
                    textTransform: "unset",
                    pointerEvents: "none",
                    "&:hover": { backgroundColor: "transparent" },
                    "&:active": { backgroundColor: "transparent" },
                    "&.Mui-disabled": { backgroundColor: "transparent" },
                    ".MuiTouchRipple-root": { display: "none" },
                  }}
                  onClick={() => setAddressChooserOpen(true)}
                >
                  {`Deliver to ${selectedAddress.name} - ${selectedAddress.city} ${selectedAddress.zip}`}
                </Button>
              ) : (
                <AddressForm
                  formTitle="Enter Shipping Address:"
                  onSave={async (address) => {
                    setSelectedAddress(address);
                  }}
                  buttonText="Save Address and Continue"
                />
              )}
            </CardContent>
          </Card>

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <ImgWithFallback
              src={primaryObituaryImageLink(
                tributeId,
                tribute.primaryImageFileNamePrefix,
                "md",
                "webp"
              )}
              fallbackSrc={primaryObituaryImageLink(
                tributeId,
                tribute.primaryImageFileNamePrefix,
                "md",
                "jpg"
              )}
              style={{
                borderRadius: "4px",
              }}
              width="200"
            />
          </Box>

          <Typography
            variant="h2"
            sx={{ color: palette.primary.dark, textAlign: "center" }}
          >
            {tribute.displayName}
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <Typography variant="subtitle1">{`${
              tribute.isFullDateOfBirth === true
                ? formatUTCDateToText(tribute.dateOfBirth)
                : formatDateYearUTC(tribute.dateOfBirth)
            } – ${
              tribute.isFullDateOfDeath === true
                ? formatUTCDateToText(tribute.dateOfDeath)
                : formatDateYearUTC(tribute.dateOfDeath)
            }`}</Typography>
          </Box>
        </Stack>
        <Stack spacing={2} mt={2.5}>
          <GiftSummaryCard product={selectedProduct} showImage={true} />

          <GiftSummaryCard product={subscription} />

          <Typography variant="body1" color="text.secondary" paragraph>
            Please review your selection before confirming. Upon continuing,
            your new tribute will be associated with the physical good you
            selected, and the subscription will be applied to it.
          </Typography>

          <Stack direction="row" spacing={2} justifyContent="flex-end">
            <Button
              variant="outlined"
              onClick={() => setAsSummary(false)}
              startIcon={<Iconify icon="mdi:arrow-left" />}
            >
              Back
            </Button>
            <Button
              variant="contained"
              onClick={handleConfirm}
              startIcon={<Iconify icon="mdi:check" />}
            >
              Confirm Selection
            </Button>
          </Stack>
        </Stack>
      </Container>
    );
  }
};
