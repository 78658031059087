export const MemorialStatus = {
  AVAILABLE_FOR_SALE: "AvailableForSale",
  OWNED_INTERRED: "OwnedInterred",
  OWNED_UNOCCUPIED: "OwnedUnoccupied",
};

export const MemorialStatusMapping = [
  { value: "AvailableForSale", label: "Available For Sale" },
  { value: "OwnedInterred", label: "Owned - Interred" },
  { value: "OwnedUnoccupied", label: "Owned - Unoccupied" },
];

export const GetMemorialStatusLabel = (value) => {
  return (
    MemorialStatusMapping.find((x) => x.value === value)?.label ||
    "Status not found"
  );
};

export const GiftStatusMapping = [
  { value: "NotPurchased", label: "Not Purchased" },
  { value: "Purchased", label: "Purchased" },
  { value: "Redeemed", label: "Redeemed" },
];

export const GetGiftStatusLabel = (value) => {
  return (
    GiftStatusMapping.find((x) => x.value === value)?.label ||
    "Status not found"
  );
};

export const ProductCategory = {
  PHYSICAL_GOOD: "PhysicalGood",
  SUBSCRIPTION: "Subscription",
  GIFT_BUNDLE: "GiftBundle",
  PARTNER_SERVICE: "PartnerService",
};

export const OrderStatus = {
  CART: "Cart",
  CHECKOUT: "Checkout",
  PROCESSING: "Processing",
  PAID: "Paid",
  PARTIALLY_FULFILLED: "PartiallyFulfilled",
  FULFILLED: "Fulfilled",
  CANCELED: "Canceled",
};

export const OrderStatusMapping = [
  { value: "Cart", label: "Cart" },
  { value: "Checkout", label: "Checkout" },
  { value: "Processing", label: "Processing" },
  { value: "Paid", label: "Paid" },
  { value: "PartiallyFulfilled", label: "Partially Fulfilled" },
  { value: "Fulfilled", label: "Fulfilled" },
  { value: "Canceled", label: "Canceled" },
];

export const GetOrderStatusLabel = (value) => {
  return (
    OrderStatusMapping.find((x) => x.value === value)?.label ||
    "Status not found"
  );
};

export const OrderLineStatus = {
  FULFILLED: "Fulfilled",
  UNFULFILLED: "Unfulfilled",
};
