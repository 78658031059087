// scroll bar
import "simplebar/src/simplebar.css";

import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";

//
import { store } from "./store";
import App from "./App";
import WindowMessageHandler from "./components/WindowMessageHandler";
import setupInterceptors from "./services/setupInterceptors";
import { AppStateProvider } from "./hooks/appState";

// ----------------------------------------------------------------------

const queryClient = new QueryClient();

export const clearCache = () => {
  queryClient.clear();
};

ReactDOM.render(
  <AppStateProvider>
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <HelmetProvider>
          <BrowserRouter>
            <WindowMessageHandler />
            <App />
          </BrowserRouter>
        </HelmetProvider>
      </QueryClientProvider>
    </Provider>
  </AppStateProvider>,
  document.getElementById("root")
);
setupInterceptors(store);
